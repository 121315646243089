import React from "react";

function NutritionFacts(props) {
  const { servingSize = {}, nutrition = [], vitamins = [] } = props.list;
  const {
    count: servingSizeCount,
    grams: servingSizeGrams,
    perContainer,
    amountPerServing
  } = servingSize;
  const {
    calories: amountPerServingCalories,
    caloriesFromFat
  } = amountPerServing;
  const chunkArray = (myArray, chunk_size) => {
    // Here to chunk vitamins
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    for (index = 0; index < arrayLength; index += chunk_size) {
      let myChunk = myArray.slice(index, index + chunk_size);
      tempArray.push(myChunk);
    }
    return tempArray;
  };
  const nutritionDetails = nutrition.map((element, index) => {
    const { label, grams, dailyValue, indent } = element;

    return (
      <tr key={index}>
        <th
          className={`font-weight-${indent ? "light" : "bold"} ${
            indent ? "pl-4" : ""
          }`}
        >
          {label}
        </th>
        <th>{grams}</th>
        <th>{dailyValue}</th>
      </tr>
    );
  });

  const vitaminContent = chunkArray(vitamins, 2).map((element, index) => {
    const singleVitamins = element.map((vitamin, index) => {
      const { label, value } = vitamin;
      return (
        <div key={index} className="w-50">
          <th>{label}</th>
          <th>{value}</th>
        </div>
      );
    });
    return (
      <tr key={index} className="d-flex justify-content-between">
        {singleVitamins}
      </tr>
    );
  });

  return (
    <div className="h6">
      <div className="d-flex flex-column">
        <span className="text-center">
          <h1>Nutrition Facts</h1>
        </span>
        <span>
          Serving Size {servingSizeCount} ({servingSizeGrams})
        </span>
        <span>Servings Per Container About {perContainer}</span>
      </div>
      <div>
        <span>Amount Per Serving</span>
        <div className="d-flex justify-content-between">
          <div>Calories {amountPerServingCalories}</div>
          <div>Calories from Fat {caloriesFromFat}</div>
        </div>
      </div>
      <table className="table table-striped pointer">
        <thead>
          <tr>
            <th> </th>
            <th> </th>
            <th>% Daily Value</th>
          </tr>
        </thead>
        <tbody>{nutritionDetails}</tbody>
      </table>
      <table className="table table-striped pointer">
        <tbody>{vitaminContent}</tbody>
      </table>
      <div>
        *Percent Daily Values are based on 2,000 calorie diet. Your daily diet.
        Your daily values may be higher or lower depending on yoru calorie needs
      </div>
      <table className="table default-info">
        <thead>
          <th />
          <th>Calories:</th>
          <th>2,000</th>
          <th>2,500</th>
        </thead>
        <tbody>
          <tr>
            <th>Total Fat</th>
            <th>Less than</th>
            <th>65g</th>
            <th>80g</th>
          </tr>
          <tr>
            <th>Sat Fat</th>
            <th>Less than</th>
            <th>20g</th>
            <th>25g</th>
          </tr>
          <tr>
            <th>Cholesterol</th>
            <th>Less than</th>
            <th>300mg</th>
            <th>300mg</th>
          </tr>
          <tr>
            <th>Sodium</th>
            <th>Less than</th>
            <th>2,400mg</th>
            <th>2,400mg</th>
          </tr>
          <tr>
            <th>Total Carbohydrate</th>
            <th />
            <th>300g</th>
            <th>375g</th>
          </tr>
          <tr>
            <th>Dietary Fiber</th>
            <th />
            <th>25g</th>
            <th>30g</th>
          </tr>
          <tr>
            <th>Protein</th>
            <th />
            <th>50g</th>
            <th>65g</th>
          </tr>
        </tbody>
      </table>
      <div className="d-flex flex-column">
        <span>Calories per gram:</span>
        <span>Fat 9 * Carbohydrate 4 * Protein 4</span>
      </div>
    </div>
  );
}

export default NutritionFacts;
