import React from "react";
import AuthenticationBanner from "./Presentational/AuthenticationBanner";
import Button from "./Presentational/Button";
import Images from "./Presentational/Images";
import NavigationLink from "./Presentational/NavigationLink"
import ProductName from "./Presentational/ProductName";
import SecondaryDataElement from "./Presentational/SecondaryDataElement";
import WarningMessage from "./Presentational/WarningMessage";

export function PresentationalPicker(element, props, value, index, setPage) {
  const data = { ...element, ...props, ...value };
  const key = `${element}-${index}`;

  switch (element.type) {
    case "Authentication Banner":
      return <AuthenticationBanner key={key} {...data} />;
    case "Button":
      return <Button {...data} key={key} setPage={setPage} />;
    case "Secondary Data Element":
      return <SecondaryDataElement {...data} key={key} />;
    case "Images":
      return <Images {...data} key={key} />;
    case "Navigation Link":
      return <NavigationLink {...data} key={key} />;
    case "Product Name":
      return <ProductName {...data} key={key} />;
    case "Warning Message":
      return <WarningMessage {...data} key={key} />;
    default:
      return null;
  }
}
