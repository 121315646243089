import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

export default function TextBlockWithJaggedBorder(props) {
  const {
    title,
    list,
    text,
    blockColor = "#A9A9A9",
    textColor = "white"
  } = props;
  const JaggedBorder = styled.div`
    background-color: ${blockColor};
    color: ${textColor};
    &::before {
      background: linear-gradient(
          -45deg,
          ${blockColor} 16px,
          red 16px,
          blue 16px,
          transparent 0
        ),
        linear-gradient(45deg, ${blockColor} 16px, transparent 0);
      background-position: left top;
      background-repeat: repeat-x;
      background-size: 22px 32px;
      content: " ";
      display: block;
      height: 32px;
      width: 100%;
      position: relative;
      bottom: 64px;
      left: 0;
    }
    &::after {
      background: linear-gradient(-45deg, transparent 16px, ${blockColor} 0),
        linear-gradient(45deg, transparent 16px, ${blockColor} 0);
      background-repeat: repeat-x;
      background-position: left bottom;
      background-size: 22px 32px;
      content: "";
      display: block;
      width: 100%;
      height: 32px;
      position: relative;
      top: 64px;
      left: 0px;
    }
  `;
  const descriptionList = list.map((element, index) => {
    return (
      <li key={index} className="m-3">
        {element}
      </li>
    );
  });

  return (
    <JaggedBorder className="zig-zag-top zig-zag-bottom">
      <Container>
        <Row>
          <Col className="roboto-slab" xs={12}>
            <h1 className="text-center w-100 mb-3">{title}</h1>
            <span className="lead">{text}</span>
            {list ? <ul className="mt-3">{descriptionList}</ul> : null}
          </Col>
        </Row>
      </Container>
    </JaggedBorder>
  );
}

TextBlockWithJaggedBorder.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  description: PropTypes.string,
  blockColor: PropTypes.string
};
