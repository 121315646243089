import React from "react";
import { Carousel } from "react-responsive-carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";

function RecommendedProducts(props) {
  const { list } = props;
  const slides =
    list !== undefined
      ? list.map((element, index) => {
          const { image, title, link, price } = element;
          return (
            <a
              href={link}
              rel="noopener noreferrer"
              key={index}
              target="_blank"
            >
              <Row className="text-dark">
                <Col xs={12}>
                  <img alt="product" src={image} />
                </Col>
                <Col xs={12}>
                  <span>{title}</span>
                </Col>
                <Col xs={12}>
                  <span>{price}</span>
                </Col>
              </Row>
            </a>
          );
        })
      : "";
  return (
    <Carousel
      className="h-100"
      showArrows={false}
      infiniteLoop={true}
      showStatus={false}
      showThumbs={false}
      centerMode
      centerSlidePercentage={33}
      emulateTouch={true}
    >
      {slides}
    </Carousel>
  );
}

export default RecommendedProducts;

RecommendedProducts.propTypes = {
  list: PropTypes.array
};
