import React from "react";
import PropTypes from "prop-types";

export default function BulletedList(props) {
  const { list } = props;

  return (
    <ul className="mt-3">
      {list.map((element, index) => {
        return <li key={index}>{element}</li>;
      })}
    </ul>
  );
}

BulletedList.propTypes = {
  list: PropTypes.array
};
