import React from "react";
export default function Specs(props) {
  const keyValues = props.props ? Object.keys(props.props) : {};
  const mainValues = props.props;
  const tableRow = props.props
    ? keyValues.map((element, index) => {
        return (
          <tr key={index}>
            <th scope="row ">
              <span className="text-uppercase">{element}</span>
            </th>
            <td>{mainValues[element]}</td>
          </tr>
        );
      })
    : null;
  return (
    <table className="table table-striped pointer">
      <tbody>{tableRow}</tbody>
    </table>
  );
}
