import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import PropTypes from "prop-types";

export default function CellPhoneCallToAction(props) {
  const {
    title,
    content,
    image,
    appleStore,
    googlePlay,
    backgroundColor = "white",
    backgroundImage
  } = props;
  const Image = styled.img`
    width: 250px;
  `;
  const AppStoreImage = styled.img`
    max-width: 150px;
  `;
  const BlockContainer = styled.div`
    background-image: url(${backgroundImage});
    background-size: cover;
    background-color: ${backgroundColor};
  `;

  return (
    <BlockContainer>
      <Container className="py-5">
        <Row>
          <Col
            className="py-4 d-flex flex-column justify-content-center font-weight-light text-center text-lg-left"
            xs={12}
            lg={8}
          >
            <h1 className="mb-2">{title}</h1>
            <span>{content}</span>
            <div className="mt-3">
              {appleStore ? (
                <a target="_blank" href={appleStore}>
                  <AppStoreImage src="https://connectx-staging.locatorx.com/images/apple-appStore-badge.svg" />
                </a>
              ) : null}
              {googlePlay ? (
                <a target="_blank" href={googlePlay}>
                  <AppStoreImage src="https://connectx-staging.locatorx.com/images/google-play-badge.png" />
                </a>
              ) : null}
            </div>
          </Col>
          <Col className="text-center" xs={12} lg={4}>
            {image ? <Image src={image} /> : null}
          </Col>
        </Row>
      </Container>
    </BlockContainer>
  );
}

CellPhoneCallToAction.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  appleStore: PropTypes.string,
  googlePlay: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string
};
