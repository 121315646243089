import React from "react";
import PropTypes from "prop-types";

export default function VideoCover(props) {
  const { video } = props;
  return (
    <video width="100%" autoPlay={true} muted loop>
      <source src={video} type="video/mp4" />
    </video>
  );
}

VideoCover.propTypes = {
  video: PropTypes.string
};
