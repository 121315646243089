import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Header from "./components/Header";
import ImageCarousel from "./components/ImageCarousel";
import BorderedLink from "./components/BorderedLink";
import Tabs from "./components/Ink/Tabs";
import BorderedSpecs from "./components/BorderedSpecs";
import TextWithTitle from "./components/TextWithTitle";
import LinkFarm from "./components/LinkFarm";
import Footer from "./components/Footer";

export default class Ink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "features"
    };
    this.switchTab = this.switchTab.bind(this);
  }

  switchTab(tab) {
    this.setState({ activeTab: tab });
  }

  render() {
    const { activeTab } = this.state;
    const { product } = this.props.product.asset;
    const { description, name, productId, propertiesMap } = product;
    const {
      images = "",
      specs,
      spec_details = [],
      price,
      description_list = [],
      promotion_highlights = [],
      link_farm = []
    } = propertiesMap;

    const logo =
      this.props.organization.length > 0
        ? this.props.organization.properties.logo
        : "https://i.imgur.com/2yr8we4.png";

    const tabs = [
      {
        label: "Features",
        value: "features",
        click: () => {
          this.switchTab("features");
        }
      },
      {
        label: "Specs",
        value: "specs",
        click: () => {
          this.switchTab("specs");
        }
      },
      {
        label: "Special Offers",
        value: "special",
        click: () => {
          this.switchTab("special");
        }
      }
    ];

    const promotionHighlights = promotion_highlights.map((element, index) => {
      return <li key={index}>{element}</li>;
    });

    return (
      <div className="ink-page">
        <Header logo={logo} />
        <Container className="mt-5">
          <Row>
            <Col xs={12} md={6}>
              <div className="h-100 product-images mb-5">
                <ImageCarousel images={images} />
              </div>
            </Col>
            <Col xs={12} md={6} className="pl-0">
              <h2 className="font-weight-light">{name}</h2>
              <ul className="mt-3 pl-3">
                {spec_details.map((element, index) => {
                  return (
                    <li key={index} className="my-2 text-muted">
                      {element}
                    </li>
                  );
                })}
              </ul>
              <div className="mt-3 font-weight-light">
                See compatible printers
              </div>
              <div className="border-bottom py-4">
                <span className="text-danger h3 font-weight-light">
                  {price}
                </span>
                <button className="mx-3 btn btn-danger"> Add to cart </button>
                <span>Ships within 1 business day</span>
              </div>
              <div className="mt-3 text-center text-md-left">
                <small className="font-weight-bold">
                  Product #{" "}
                  <span className="text-muted text-uppercase">{productId}</span>
                </small>
              </div>
            </Col>
          </Row>
          <Row className="my-4">
            <Col xs={12} md={6}>
              <BorderedLink
                icon="fa-tags"
                link="www.google.com"
                content="Weekly deals! Shop our best deals of the week."
              />
            </Col>
          </Row>
          <Row className="border-bottom">
            <Col xs={12} className="border-bottom" />
            <Col xs={12} md={12} className="nav-tabs border-bottom">
              <Tabs tabs={tabs} activeClass={activeTab} />
            </Col>
            <Col xs={12} md={12}>
              {activeTab === "features" ? (
                <TextWithTitle content={description} list={description_list} />
              ) : null}
              {activeTab === "specs" ? <BorderedSpecs list={specs} /> : null}
              {activeTab === "special" ? (
                <div className="mt-3">
                  <ul>{promotionHighlights}</ul>
                </div>
              ) : null}
            </Col>
            <Col className="link-farm text-left border-top pt-5" xs={12}>
              <LinkFarm list={link_farm} />
            </Col>
          </Row>
          <Row className="user-links mb-5 ">
            <Col xs={12} md={9} className="my-5">
              <ul>
                <li className="pr-4 font-weight-bold">
                  <a href="www.google.com">My Shopping links</a>
                </li>
                <li className="pr-4">
                  <a href="www.google.com">Order Status</a>
                </li>
                <li className="pr-4">
                  <a href="www.google.com">Manage subscriptions</a>
                </li>
                <li className="pr-4">
                  <a href="www.google.com">Returns & Exchanges</a>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={3} className="border-left d-flex">
              <img
                src="https://i.imgur.com/foyWpZ4.png"
                alt="agent"
                className="img-fluid align-self-center"
              />

              <div className="align-self-center h5">
                <div>Need help?</div>
                <small>
                  <a href="www.google.com">Contact us now</a>
                </small>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer logo={logo} />
      </div>
    );
  }
}
