import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function Images(props) {
  const {
    height = "auto",
    image,
    keepAspectRatio = true,
    width = "auto",
  } = props;

  const useStyles = makeStyles(() => {
    return {
      container: {
        marginButton: '2rem',
        marginTop: '2rem',
      },
      image: {
        height: keepAspectRatio ? "auto" : `${height}px`,
        width: keepAspectRatio ? "100%" : `${width}px`,
      },
    };
  });

  const classes = useStyles();
  return (
    <Grid className={classes.container} container>
      <Grid item xs={12}>
        {image ? (
          <img className={classes.image} src={image} alt="product" />
        ) : null}
      </Grid>
    </Grid>
  );
}
