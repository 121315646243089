import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button as MaterialButton } from "@material-ui/core";

export default function Button(props) {
  const {
    backgroundColor = "#3D5BA9",
    // buttonFunction options === "Close" || "Navigate" || "External"
    buttonFunction = "Navigate",
    buttonText = "",
    font = { label: "DejaVu Sans", value: "DejaVu Sans" },
    fontColor = "#FFFFFF",
    fontModification = "bold",
    fontSize = "15",
    height = "40",
    setPage,
    target,
    textAlignment = "",
    width = "350",
  } = props;

  const useStyles = makeStyles(() => {
    return {
      button: {
        backgroundColor,
        color: fontColor,
        fontFamily: font.label ? font.label : font,
        fontSize: `${fontSize}px`,
        height: `${height}px`,
        width: `${width}px`,
        textAlign: textAlignment,
        fontStyle: fontModification === "italic" ? "italic" : "inherit",
        fontWeight: fontModification === "bold" ? "bold" : "inherit",
      },
      buttonContainer: {
        display: "flex",
        justifyContent: "center",
      },
      container: {
        marginBottom: "2rem",
        marginTop: "6rem",
      },
    };
  });

  const classes = useStyles();
  return (
    <Grid className={classes.container} container>
      <Grid className={classes.buttonContainer} item xs={12}>
        {buttonFunction === "External URL" ? (
          <a href={target}>
            <MaterialButton
              className={classes.button}
              fullWidth
              variant="contained"
            >
              {buttonText}
            </MaterialButton>
          </a>
        ) : (
          <MaterialButton
            className={classes.button}
            fullWidth
            onClick={() => {
              if (buttonFunction === "Close Page") {
                // Prompting close will tell the app to close the window
                prompt("close");
              } else if (buttonFunction === "Connect Page") {
                setPage(target);
              }
            }}
            variant="contained"
          >
            {buttonText}
          </MaterialButton>
        )}
      </Grid>
    </Grid>
  );
}
