import React from "react";
import { lazy, Suspense } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const DynamicIconRender = lazy(() =>
  import("../../../components/IconPicker/DynamicIconRender")
);

export default function NavigationLink(props) {
  const {
    backgroundColor = "#3D5BA9",
    buttonLabel = "Label",
    buttonStyle,
    externalLinkUrl,
    font = { label: "Lato", value: "'Lato', sans-serif" },
    fontColor = "#000000",
    fontModification,
    fontSize = "25",
    iconColor = "#ffffff",
    image = [],
    navigationType,
    selectedIcon = null,
    textAlignment = "left",
    videoLabel = "",
    videoLinkUrl = "",
  } = props;

  const imgUrl = image.length !== 0 ? image[0]?.url : "";

  // The regex below will grab a youtube video id from a given URL
  // Stolen from https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
  // ... it wasnt really stolen, but I felt cool writting that.
  let re =
    /(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
  let id = videoLinkUrl.match(re) ? videoLinkUrl.match(re)[7] : null;

  const useStyles = makeStyles((theme) => {
    return {
      button: {
        backgroundColor,
        backgroundImage:
          navigationType === "Video Link URL" && id
            ? `url(https://i.ytimg.com/vi/${id}/hqdefault.jpg)`
            : buttonStyle === "Upload Image" || buttonStyle === "Existing Image"
            ? `url(${imgUrl})`
            : null,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "87.38px",
        minWidth: "343px",
      },
      container: {
        padding: "0 1rem",
        margin: "1rem 0",
      },
      font: {
        color: fontColor,
        fontFamily: font.value,
        fontSize: `${fontSize}px`,
        fontStyle: fontModification === "italic" ? "italic" : "inherit",
        fontWeight: fontModification === "bold" ? "bold" : "inherit",
        textAlign: textAlignment,
      },
      icon: {
        color: iconColor,
        height: "auto",
        margin: ".2rem 0",
        width: "65px",
      },
    };
  });

  const classes = useStyles();
  const button = (
    <Grid item xs={12}>
      <a
        href={
          navigationType === "Video Link URL" ? videoLinkUrl : externalLinkUrl
        }
      >
        <Button className={classes.button} fullWidth={true} size="large">
          {selectedIcon ? (
            <Suspense fallback={<div />}>
              <DynamicIconRender
                className={classes.icon}
                iconName={selectedIcon}
                style={{ fill: iconColor, height: "auto", width: "65px" }}
              />
            </Suspense>
          ) : null}
        </Button>
      </a>
    </Grid>
  );

  return (
    <Grid className={classes.container} container>
      <Grid item xs={12}>
        <Typography className={classes.font} variant="h6">
          {navigationType === "Video Link URL" ? videoLabel : buttonLabel}
        </Typography>
        {navigationType === "External Link URL" && buttonStyle !== ""
          ? button
          : null}
        {navigationType === "Video Link URL" && id ? button : null}
      </Grid>
    </Grid>
  );
}
