import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import ProductDescription from "./ProductDescription";
import styled from "styled-components";

export default function TextWithBackgroundImage(props) {
  const { image, description, title } = props;
  const ImageCover = styled.div`
    background: url(${image}) no-repeat center center fixed;
    background-size: cover;
  `;
  return (
    <ImageCover className="bg-secondary shadow mt-5 cover">
      <Container className="text-light py-5">
        <Row>
          <Col xs={12} md={12}>
            <span className="text-center">
              <h2 className="mb-4">{title}</h2>
            </span>
            <ProductDescription description={description} />
          </Col>
        </Row>
      </Container>
    </ImageCover>
  );
}

TextWithBackgroundImage.propTypes = {
  image: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string
};
