import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function AuthenticationBanner(props) {
  const { text = "" } = props

  const useStyles = makeStyles(() => {
    return {
      check:{
        marginRight:'.5rem'
      },
      container: {
        backgroundColor: "#45793A",
        color:'white',
        marginBottom: '1rem',
        marginTop: '1rem',
      },
      text : {
        fontSize:"30px",
        fontWeight: "bold",
        paddingBottom:'1rem',
        paddingTop:'1rem',
        textAlign:"center",
      }
    };
  });
  const classes = useStyles()
  
  return (
    <Grid className={classes.container} container>
      <Grid className={classes.text} item xs={12}>
       <i className={`fas fa-check ${classes.check}`} />
        {text}
      </Grid>
    </Grid>
  )
}