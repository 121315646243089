import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

const Gradient = styled.div`
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    ${props => props.theme.primaryColor} 1%,
    rgba(0, 212, 255, 1) 100%
  );
  display: flex;
`;
function Homepage(props) {
  const organization = props.organization ? props.organization : {};
  const { properties = {} } = organization;
  const { logo = "", primaryColor = "" } = properties;
  const theme = {
    primaryColor
  };
  return (
    <ThemeProvider theme={theme}>
      <Gradient className="min-vh-100 min-vw-100">
        <Container>
          <Row className="h-100 justify-content-center">
            <Col className="align-self-center" xs={6}>
              <img alt="company logo" className="img-fluid" src={logo} />
            </Col>
          </Row>
        </Container>
      </Gradient>
    </ThemeProvider>
  );
}

export default Homepage;
