import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types"

export default function TextWithLink(props) {
  const { text, link, textAlignment } = props
  return (
    <Container>
      <Row>
        <Col className={textAlignment} xs={12}>
          <a href={link} target="_blank">
            {text}
          </a>
        </Col>
      </Row>
    </Container>
  )
}

TextWithLink.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  textAlignment: PropTypes.string
}