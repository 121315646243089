import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PictureAndText from "./components/PictureAndText";
import TitleAndList from "./components/TitleAndList";
import Image from "./components/Image";
import Quote from "./components/Quote";
import Hero from "./components/Hero";
import Footer from "./components/Footer";

function Car(props) {
  const { name, description, propertiesMap } = props.product.asset.product;
  const {
    co2DisclaimerGroup,
    teaser,
    sections,
    video,
    hero,
    titleAndList
  } = propertiesMap;

  const logo =
    props.organization.length > 0
      ? props.organization.properties.logo
      : "https://i.imgur.com/2yr8we4.png";

  return (
    <div className="car-page">
      <video width="100%" autoPlay={true} muted loop>
        <source src={video} type="video/mp4" />
      </video>
      <Container>
        <Row>
          <Col className="description h2 my-5 font-weight-light" xs={12}>
            {description}
          </Col>
          <Col xs={12}>
            <ul className="list-inline text-center border-top border-bottom my-5">
              {co2DisclaimerGroup.map((element, index) => {
                const { title, value } = element;
                return (
                  <li className="list-inline-item mx-2 py-3" key={index}>
                    <b className="mr-2">{title}</b>
                    <span className="font-weight-light">{value}</span>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col xs={12}>
            <TitleAndList title={name} list={titleAndList} />
          </Col>
        </Row>
        <PictureAndText
          image={teaser.image}
          content={teaser.content}
          title={teaser.title}
        />
        {sections.map((element, index) => {
          const { type } = element;
          if (type === "image") {
            return (
              <Image
                key={index}
                image={element.image}
                subtext={element.subText}
              />
            );
          } else if (type === "quote") {
            const { quote, author, position, image } = element;
            return (
              <Quote
                quote={quote}
                author={author}
                position={position}
                image={image}
                key={index}
              />
            );
          }
          return null;
        })}
        <Hero image={hero.image} subText={hero.subText} title={hero.title} />
      </Container>
      <Footer logo={logo} />
    </div>
  );
}

export default Car;
