import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

export default function LinkFarm(props) {
  const { list } = props;
  const StyledList = styled.ul`
    list-style-type: none;
    li {
      display: inline;
      padding: 0 13px;
      border-right: 1px solid #5a5a5a;
      line-height: 12px;

      a {
        text-decoration: none;
        color: #5a5a5a;
        font-size: 14px;
      }
    }
  `;
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <StyledList>
            {list.map((element, index) => {
              return (
                <li key={index} className="border-right pr-3">
                  <a href={element.link}>{element.title}</a>
                </li>
              );
            })}
          </StyledList>
        </Col>
      </Row>
    </Container>
  );
}

LinkFarm.propTypes = {
  list: PropTypes.array
};
