import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function IFrame(props) {
  const {
    source,
    width,
    height,
    frameBorder = 0,
    marginHeight = 0,
    marginWidth = 0
  } = props;
  return (
    <Container className="my-5">
      <Row>
        <Col className="text-center" xs={12}>
          <iframe
            src={source}
            width={width}
            height={height}
            frameBorder={frameBorder}
            marginHeight={marginHeight}
            marginWidth={marginWidth}
          >
            Loading…
          </iframe>
        </Col>
      </Row>
    </Container>
  );
}
