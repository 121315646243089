import React from "react";
import BorderedSpecs from "../components/BorderedSpecs";
import BulletedList from "../components/BulletedList";
import BulletListWithImages from "../components/BulletListWithImages";
import BusinessCard from "../components/BusinessCard";
import CellPhoneCallToAction from "../components/CellPhoneCallToAction";
import Hero from "../components/Hero";
import IFrame from "../components/IFrame";
import IframeNextToIframe from "../components/IframeNextToIframe";
import Image from "../components/Image";
import LinkFarm from "../components/LinkFarm";
import ListWithImages from "../components/ListWithImages";
import ModularText from "../components/ModularText";
import NutritionAndCardList from "../components/NutritionAndCardList";
import PictureAndText from "../components/PictureAndText";
import ProductDetails2 from "../components/ProductDetails2";
import PromotionalButton from "../components/PromotionalButton";
import Quote from "../components/Quote";
import Redirect from "../components/Redirect";
import ScrollingCarouselWithTitle from "../components/ScrollingCarouselWithTitle";
import SimpleCard from "../components/SimpleCard";
import PCAResponseCard from "../components/PCAResponseCard";
import TabbedSections from "../components/TabbedSections";
import TextBlockWithJaggedBorder from "../components/TextBlockWithJaggedBorder";
import TextWithBackgroundImage from "../components/TextWithBackgroundImage";
import TextWithLink from "../components/TextWithLink";
import TextWithTitle from "../components/TextWithTitle";
import TitleAndList from "../components/TitleAndList";
import TitledInlineList from "../components/TitledInlineList";
import VideoCover from "../components/VideoCover";
import VideoWithList from "../components/VideoWithList";

export default function ComponentPicker(element, props) {
  switch (element.type) {
    case "borderedSpecs":
      return <BorderedSpecs {...element} {...props} />;
    case "bulletedList":
      return <BulletedList {...element} {...props} />;
    case "bulletListWithImages":
      return <BulletListWithImages {...element} {...props} />;
    case "businessCard":
      return <BusinessCard {...element} {...props} />;
    case "cellPhoneCallToAction":
      return <CellPhoneCallToAction {...element} {...props} />;
    case "hero":
      return <Hero {...element} {...props} />;
    case "iFrame":
      return <IFrame {...element} {...props} />;
    case "iframeNextToIframe":
      return <IframeNextToIframe {...element} {...props} />;
    case "image":
      return <Image {...element} {...props} />;
    case "linkFarm":
      return <LinkFarm {...element} {...props} />;
    case "listWithImages":
      return <ListWithImages {...element} {...props} />;
    case "modularText":
      return <ModularText {...element} {...props} />;
    case "nutritionAndCardList":
      return <NutritionAndCardList {...element} {...props} />;
    case "pictureAndText":
      return <PictureAndText {...element} {...props} />;
    case "productDetails2":
      return <ProductDetails2 {...element} {...props} />;
    case "promotionalButton":
      return <PromotionalButton {...element} {...props} />;
    case "quote":
      return <Quote {...element} {...props} />;
    case "redirect":
      return <Redirect {...element} {...props} />;
    case "scrollingCarouselWithTitle":
      return <ScrollingCarouselWithTitle {...element} {...props} />;
    case "simpleCard":
      return <SimpleCard {...element} {...props} />;
    case "PCAResponseCard":
      return <PCAResponseCard {...element} {...props} />;
    case "tabbedSections":
      return <TabbedSections {...element} {...props} />;
    case "textBlockWithJaggedBorder":
      return <TextBlockWithJaggedBorder {...element} {...props} />;
    case "TextWithBackgroundImage":
      return <TextWithBackgroundImage {...element} {...props} />;
    case "textWithLink":
      return <TextWithLink {...element} {...props} />;
    case "textWithTitle":
      return <TextWithTitle {...element} {...props} />;
    case "titleAndList":
      return <TitleAndList {...element} {...props} />;
    case "titledInlineList":
      return <TitledInlineList {...element} {...props} />;
    case "videoCover":
      return <VideoCover {...element} {...props} />;
    case "videoWithList":
      return <VideoWithList {...element} {...props} />;
    default:
      return null;
  }
}
