import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

export default function Footer(props) {
  const { socialMedia = [], logo } = props;
  const names = socialMedia.map((element, index) => {
    const { icon, link } = element;
    return (
      <Col xs={3} md={1} key={index}>
        <a
          className="text-white"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className={`${icon} fa-2x`} />
        </a>
      </Col>
    );
  });
  return (
    <div className="bg-dark py-5 text-light">
      <Container>
        <Row className="justify-content-center text-center mb-3">{names}</Row>
        <Row className="justify-content-center text-center">
          <Col xs={5} md={2}>
            <img alt="company logo" className="img-fluid" src={logo} />
          </Col>
          <Col className="mt-3" xs={12}>
            <span>© 2019 Copyright: LocatorX. All Rights Reserved.</span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Footer.propTypes = {
  logo: PropTypes.string,
  socialMedia: PropTypes.array
};
