import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";

export default function TitledInlineList(props) {
  const { list } = props;
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <ul className="list-inline text-center border-top border-bottom my-5">
            {list.map((element, index) => {
              const { title, value } = element;
              return (
                <li className="list-inline-item mx-2 py-3" key={index}>
                  <b className="mr-2">{title}</b>
                  <span className="font-weight-light">{value}</span>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

TitledInlineList.propTypes = {
  list: PropTypes.array
};
