import React, { useEffect, useState } from "react";
import { templatePicker } from "./utils";
import { retrieveAsset } from "../../Utils/ApiCalls/Assets/assets";

export default function Consumer(props) {
  const { match = {} } = props
  const { params  = {} } = match
  const [state, setState] = useState({
    assetId: "",
    product: {},
  });

  useEffect(() => {
    console.log('useEffect')
    const assetId = props.assetId || params.assetId;

    if (assetId) {
      retrieveAsset(assetId, props).then((res) => {
        const { asset } = res;
        const { product } = asset;
        const { name, organizationEntity, propertiesMap = {} } = product;
        const { productType = {} } = propertiesMap;
        const { properties = {} } = organizationEntity;
        const { favicon = "" } = properties;

        // This is where we set the page title and favicon
        const link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = favicon;
        document.title = name;

        setState((prevState) => ({
          ...prevState, 
          asset,
          assetId,
          organization: organizationEntity,
          product,
          productType,
        }));
      });
    }
  }, []);

  return <div> {templatePicker(state.product, props, state)} </div>;
}
