import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import RelatedProducts from "./RelatedProducts";

export default function ScrollingCarouselWithTitle(props) {
  const { title } = props;
  return (
    <Container>
      <Row>
        <Col className="my-5 related-products" sm={12} md={12}>
          <div className="text-center related-products my-4 h2 w-100 overflow-hidden">
            <span className="fancy">{title}</span>
          </div>
          <RelatedProducts {...props} />
        </Col>
      </Row>
    </Container>
  );
}

ScrollingCarouselWithTitle.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array
};
