import React from "react";
import PropTypes from 'prop-types'
import { Container, Row, Col } from "react-bootstrap";
import YouTube from "react-youtube";
import SpecItems from "./SpecItems";

export default function VideoWithList(props){
  const { youtubeVideos = [], listTitle } = props
  const opts = {
    width: '440'
  }
  return (
    <Container className="my-5">
      <Row>
        <Col className="mt-5" xs={12} md={6}>
          {
            youtubeVideos.map((element, index) => {
              return (
                <YouTube
                  containerClassName={"h_iframe"}
                  key={index}
                  videoId={element}
                  opts={opts}
                  
                />
              );
            })
          }
        </Col>
        <Col className="specs" sm={12} md={6}>
          <div className="text-center specs my-4 h2 w-100">
            <span className="fancy">{listTitle}</span>
          </div>
          <SpecItems {...props} />
        </Col>
      </Row>
    </Container>
  )
}

VideoWithList.propTypes = {
  youtubeVideos: PropTypes.array,
  listTitle: PropTypes.string
}