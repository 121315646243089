import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";

export default function PictureAndText(props) {
  const { image, content, title, buttonText, buttonLinkOnClick } = props;
  return (
    <Container>
      <Row className="my-5 align-items-center foo">
        <Col xs={12} md={7}>
          <img alt="promotional" className="w-100" src={`${image}`} />
        </Col>
        <Col xs={12} md={5}>
          <div className="display-4 mb-3 playfair">{title}</div>
          <div className="font-weight-light mb-4">{content}</div>
          {buttonText && buttonLinkOnClick ? (
            <div>
              <button
                onclick={() => {
                  buttonLinkOnClick();
                }}
                className="w-100 btn btn-primary"
              >
                {buttonText}
              </button>
            </div>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

PictureAndText.propTypes = {
  image: PropTypes.string,
  content: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLinkOnClick: PropTypes.func
};
