import React from "react";
import PropTypes from "prop-types";

export default function ProductDetailsWithBulletList(props) {
  const { list, label, productId } = props;
  const fieldvalues = list.map((element, index) => {
    return (
      <li key={index} className="my-2 text-muted">
        {element}
      </li>
    );
  });
  return (
    <div>
      <h2 className="font-weight-light">{label}</h2>
      <ul className="mt-3 pl-3">{fieldvalues}</ul>
      <div className="mt-3 text-center text-md-left border-top pt-2">
        {productId ? (
          <small className="font-weight-bold">
            Product #
            <span className="text-muted text-uppercase">{productId}</span>
          </small>
        ) : null}
      </div>
    </div>
  );
}

ProductDetailsWithBulletList.propTypes = {
  list: PropTypes.array,
  label: PropTypes.string,
  productId: PropTypes.string
};
