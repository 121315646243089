import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Carousel } from "react-responsive-carousel";

function PCAResponseCard(props) {
  const {
    asset = {},
    backgroundColor = "",
    buttonColor = "",
    organization = {},
  } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: backgroundColor,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0 1rem",
      marginBottom: "1rem",
    },
    bold: {
      fontWeight: "bold",
    },
    logo: {
      width: "80%",
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      margin: "1rem 0",
    },
    button: {
      backgroundColor: "#6D6E70",
      "&:hover": {
        background: "#A6A8AB",
      },
      minWidth: "7rem",
      marginRight: "1rem",
      marginLeft: "1rem",
    },
    antValid: {
      backgroundColor: "rgb(135, 208, 104)",
      margin: "0 5px",
      padding: "0 7px",
      color: "white",
      frontSize: ".85rem",
      border: "1px solid #d9d9d9",
      borderRadius: "2px",
    },
    warning: {
      color: "red",
      fontWeight: "bolder",
    },
    customDataFieldContainer: {
      display: "flex",
      marginTop: ".7rem",
    },
    customAttributeValue: {
      fontWeight: "700",
      marginLeft: ".5rem",
    },
    buttonContainer: {
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
      // position: "sticky",
      // bottom: "0",
      padding: ".7rem 0",
      // boxShadow: "0px 10px 30px -12px #000000;",
    },
  }));

  const classes = useStyles();
  const { propertiesMap: assetsPropertiesMap } = asset.asset;
  const batch = asset.asset.batch || {};
  const product = asset.asset.product || {};
  const { propertiesMap: batchPropertiesMap = {} } = batch;
  const {
    selectedProductImages = [],
    selectedProductLevelData = [],
    selectedBatchLevelData,
    selectedItemLevelData = [],
    linkToWebPage = false,
    webPageUrl,
    buttonLabel,
  } =
    batchPropertiesMap.template.find((element) => element.type === "PCACard") ||
    {};
  const organizationPropertiesMap = organization.propertiesMap || {};
  const { logo } = organizationPropertiesMap;

  // Checks for query param "valid", letting us know if the api has flagged something
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let validParam = params.get("valid");

  const validationMessage =
    validParam && validParam !== "false" ? (
      <Typography align="center" variant="body1" gutterBottom>
        <span>The</span>
        <span className={classes.antValid}>✓ Valid</span>
        <span>
          message tells you that no counterfeit products bearing the same code
          have been detected.
        </span>
      </Typography>
    ) : (
      <Typography
        className={classes.warning}
        align="center"
        variant="body1"
        gutterBottom
      >
        WARNING: Potential problem noted - Compare data below with product
      </Typography>
    );

  const listItem = (element) => {
    return (
      <Grid
        className={classes.customDataFieldContainer}
        item
        key={element.value}
        xs={12}
      >
        <Typography align="center" variant="body1" gutterBottom>
          {element.label}
        </Typography>
        <span className={classes.customAttributeValue}>{element.value}</span>
      </Grid>
    );
  };

  return (
    <>
      <Grid className={classes.root} container>
        {/* Logo */}
        {logo ? (
          <Grid className={classes.imageContainer} item xs={12}>
            <img alt="company logo" className={classes.logo} src={logo.url} />
          </Grid>
        ) : null}

        {/* Image */}
        {selectedProductImages.length > 0 ? (
          <Grid className={classes.imageContainer} item xs={12}>
            <Carousel showThumbs={false} autoPlay>
              {selectedProductImages.map((element) => {
                return (
                  <div key={element.url}>
                    <img alt="product" src={element.url} />
                  </div>
                );
              })}
            </Carousel>
          </Grid>
        ) : null}

        {/* Header */}
        <Grid className={classes.imageContainer} item xs={12}>
          <Typography align="center" variant="h4" gutterBottom>
            {product.name}
          </Typography>
        </Grid>

        {/* LocatorX Promo */}
        <Grid className={classes.imageContainer} item xs={12}>
          <Typography align="center" variant="body1" gutterBottom>
            {organization.name} uses LocatorX's Product Certificate Authority™
            to fight product counterfeiting.
          </Typography>
        </Grid>

        {/* Validation Message */}
        <Grid className={classes.imageContainer} item xs={12}>
          {validationMessage}
        </Grid>

        {/* Product Level Data */}
        {selectedProductLevelData.length > 0
          ? selectedProductLevelData.map((element) => listItem(element))
          : null}

        {/* Batch Level Data */}
        {selectedBatchLevelData.length > 0
          ? selectedBatchLevelData.map((element) => listItem(element))
          : null}

        {/* Item Level Data Elements */}
        {selectedItemLevelData.length > 0
          ? selectedItemLevelData.map((element) => {
              let item = {};
              if (assetsPropertiesMap[element.label]) {
                item = {
                  value: assetsPropertiesMap[element.label],
                  label: element.label,
                };
              }
              return listItem(item);
            })
          : null}
      </Grid>

      {/* Buttons */}
      <Grid className={`${classes.buttonContainer} `} item xs={12}>
        {linkToWebPage ? (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              window.location = webPageUrl;
            }}
          >
            {buttonLabel}
          </Button>
        ) : null}

        <Button
          className={classes.button}
          color="primary"
          onClick={() => {
            prompt("close");
          }}
          variant="contained"
        >
          Close
        </Button>
      </Grid>
    </>
  );
}

export default PCAResponseCard;
