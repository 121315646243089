import React from "react";
import PropTypes from "prop-types";

function ProductDetails2(props) {
  const { label, list = [] } = props;
  const fieldvalues = list.map((element, index) => {
    const { label, value } = element;
    return (
      <div key={index} className="my-2">
        <h6
          className={`font-weight-bold ${
            label === "Description" ? "lead" : ""
          }`}
        >
          {label}
        </h6>
        {value}
      </div>
    );
  });
  return (
    <div>
      <h1 className="display-4">{label}</h1>
      {fieldvalues}
    </div>
  );
}

export default ProductDetails2;

ProductDetails2.propTypes = {
  label: PropTypes.string,
  list: PropTypes.array
};
