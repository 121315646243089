import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import SocialMedia from "../Utils/SocialMedia";
import styled from "styled-components";

export default function BusinessCard(props) {
  const {
    name,
    image,
    position,
    socialMedia = [],
    paragraphs = [],
    logo
  } = props;

  const ProfilePicture = styled.img`
    height: auto;
    max-width: 80%;
  `;

  const CompanyLogo = styled.img`
    max-width: 35%;
  `;

  const Name = styled.h2`
    font-weight: 900;
  `;

  return (
    <Container className="my-5">
      <Row>
        <Col className="text-center" xs={12} lg={5}>
          <ProfilePicture className="img-fluid" alt="profile" src={image} />
          <div className="d-flex justify-content-center mt-4">
            {SocialMedia(socialMedia)}
          </div>
        </Col>
        <Col
          xs={12}
          lg={7}
          className="d-flex flex-column justify-content-between pb-4 mt-4 text-lg-left  text-center roboto"
        >
          <div className="d-flex flex-column">
            <Name className="h2 mb-1 font-weight-bold gothic">{name}</Name>
            <h3 className="mb-3"> {position}</h3>
            <div className="my-2">
              {logo ? <CompanyLogo alt="company logo" src={logo} /> : null}
            </div>
            {paragraphs.map((element, index) => {
              return (
                <span className="py-3" key={index}>
                  {element}
                </span>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

BusinessCard.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  position: PropTypes.string,
  logo: PropTypes.string,
  socialMedia: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      link: PropTypes.string,
      type: PropTypes.string
    })
  ),
  paragraphs: PropTypes.array
};
