import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import BorderedLink from "./BorderedLink";

export default function PromotionalButton(props) {
  const { icon, link, content } = props;
  return (
    <Container className="border-bottom my-4">
      <Row className="my-4">
        <Col xs={12} md={6}>
          <BorderedLink icon={icon} link={link} content={content} />
        </Col>
      </Row>
    </Container>
  );
}

PromotionalButton.propTypes = {
  icon: PropTypes.string,
  link: PropTypes.string,
  content: PropTypes.string
};
