import React from "react";
import Proptypes from "prop-types";

export default function ProductDescription(props) {
  const { description = "" } = props;
  return <div className="lead">{description}</div>;
}

ProductDescription.propTypes = {
  description: Proptypes.string
};
