import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

export default function IframeNextToIframe(props) {
  const { iFrames = [] } = props;
  const mappedIFrames = iFrames.map((element, index) => {
    const {
      source,
      width,
      height,
      frameBorder = 0,
      marginHeight = 0,
      marginWidth = 0,
      type = ""
    } = element;

    return (
      <Col xs={12} lg={6} key={index}>
        {type === "vidyard" ? (
          <img
            style={{ width: "100%", margin: "auto", display: "block" }}
            src="https://connectx-staging.locatorx.com/images/vidScreen.png"
            onClick={() => {
              window.open(
                "https://connectx-staging.locatorx.com/promoVid.html",
                "",
                "width=800,height=470"
              );
            }}
          />
        ) : (
          <iframe
            src={source}
            width={width}
            height={height}
            frameBorder={frameBorder}
            marginHeight={marginHeight}
            marginWidth={marginWidth}
          >
            Loading…
          </iframe>
        )}
      </Col>
    );
  });
  return (
    <Container>
      <Row>{mappedIFrames}</Row>
    </Container>
  );
}

IframeNextToIframe.propTypes = {
  iFrames: PropTypes.array
};
