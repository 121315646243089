import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";
import Proptypes from "prop-types";

const CarouselImage = styled.img`
  max-height: 350px;
  width: auto !important;
`;

export default function ImageCarousel(props) {
  const { images = [] } = props;
  const slides = images.length > 0 && (
    <Carousel
      className="h-100"
      autoPlay={true}
      showArrows={false}
      emulateTouch={true}
      showStatus={false}
      showThumbs={false}
    >
      {images.map((image, index) => (
        <div key={index}>
          <CarouselImage className="img-fluid" src={image} />
        </div>
      ))}
    </Carousel>
  );

  return slides;
}

ImageCarousel.propTypes = {
  images: Proptypes.array
};
