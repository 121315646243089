import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

function Quote(props) {
  const { quote, author, position, image } = props;

  return (
    <Container>
      <Row className="my-5">
        <Col xs={12} md={6}>
          {image ? <img alt="quote" className="img-fluid" src={image} /> : null}
        </Col>
        <Col xs={12} md={6}>
          <div className="h1 playfair">"{quote}"</div>
          <footer className="blockquote-footer">
            {author} <cite title="Source Title">{position}</cite>
          </footer>
        </Col>
      </Row>
    </Container>
  );
}

export default Quote;

Quote.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  position: PropTypes.string,
  image: PropTypes.string
};
