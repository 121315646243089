import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card } from "react-bootstrap";
import styled from "styled-components";

export default function SimpleCard(props) {
  const { buttons = [], image, list = [], text, title } = props;
  
  return (
    <Container>
      <Row>
        <Col sm={12} md={12}>
          <Card>
            <Col className="my-1 text-center" sm={12} md={12}>
              <h1>{title}</h1>
            </Col>
            <Col className="my-1 text-center" sm={12} md={12}>
              <img alt="product-image" className="w-75" src={image} />
            </Col>
            <Col className="my-1 text-center" sm={12} md={12}>
              <h4>{text}</h4>
            </Col>
            <Col className="my-1 h5" sm={12} md={12}>
              <ul>
                {list.map((element, index) => (
                  <li key={index}>{element}</li>
                ))}
              </ul>
            </Col>
            <Col className="my-1" sm={12} md={12}>
              {buttons.map((element, index) => {
                const { buttonColor = '#0693C8', label, link } = element
                const BorderedButton = styled.a`
                background-color: ${buttonColor};
                border-color: ${buttonColor};
              `;
                return (
                <BorderedButton
                  className="btn btn-block text-white my-3"
                  href={link}
                  key={index}
                >
                  {label}
                </BorderedButton>
              )})}
            </Col>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

SimpleCard.propTypes = {
  buttons: PropTypes.array,
  image: PropTypes.string,
  list: PropTypes.array,
  text: PropTypes.string,
  title: PropTypes.string,
};
