import React from "react";
import styled from "styled-components";

export default function Tabs(props) {
  const { tabs = [], activeClass } = props;
  const StyledList = styled.ul`
    list-style-type: none;
    cursor: pointer;
    li {
      display: inline;
    }
  `;
  return (
    <StyledList className="pl-0 py-3 mb-0">
      {tabs.map((element, index) => {
        const { click, label } = element;
        const listElement = click ? (
          <li
            className={`mr-5 position-relative ${
              activeClass === label ? "active" : ""
            }`}
            key={index}
            onClick={() => {
              click();
            }}
          >
            {label}
          </li>
        ) : (
          <li key={index}>{label}</li>
        );

        return listElement;
      })}
    </StyledList>
  );
}
