import React from "react";
import styled from "styled-components";
import PropTypes from 'prop-types'

export default function Card(props) {
  const { image, title, description, link, textColor = 'black', cardBackgroundColor = 'white' } = props;
  const CardBody = styled.div`
    background-color: ${cardBackgroundColor};
  `
  const CardText = styled.p`
    color: ${textColor};
  `;
  const CardLink = styled.a`
    &:hover {
      text-decoration: none;
    }
  `;
  return (
    <CardBody
      className="card align-self-center mt-3 shadow-sm"
      style={{ width: "18rem" }}
    >
      <CardLink href={link} rel="noopener noreferrer" target="_blank">
        <img className="card-img-top" src={image} alt="Card" />
        <div className="card-body">
          <CardText className="text-center font-weight-bold h6">
            {title}
          </CardText>
          <CardText className="card-text">{description}</CardText>
        </div>
      </CardLink>
    </CardBody>
  );
}

Card.propTypes= {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  textColor: PropTypes.string,
  cardBackgroundColor: PropTypes.string
}