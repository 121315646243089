import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function WarningMessage(props) {
  const { font, fontModification, fontSize, text, textAlignment } = props;

  const useStyles = makeStyles(() => {
    return {
      container: {
        padding: "0 1rem",
      },
      icon: {
        alignItems: "center",
        color: "red",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        "&:hover": {
          color: "red",
        },
      },
      label: {
        fontWeight: "bold",
        fontSize: "30px",
      },
      noHover: {
        color: "red !important",
      },
      text: {
        fontFamily: font.label ? font.label : font,
        fontSize: fontSize ? `${fontSize}px` : "25px",
        fontStyle: fontModification === "italic" ? "italic" : "inherit",
        fontWeight: fontModification === "bold" ? "bold" : "inherit",
        textAlign: textAlignment,
      },
    };
  });

  const classes = useStyles();
  return (
    <Grid className={classes.container} container>
      <Grid className={classes.icon} item xs={12}>
        <i className={`fas fa-exclamation-triangle fa-7x ${classes.noHover}`} />
        <span className={classes.label}>WARNING</span>
      </Grid>
      <Grid className={classes.text} item xs={12}>
        <span>{text}</span>
      </Grid>
    </Grid>
  );
}
