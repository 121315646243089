import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import NutritionalFacts from "./NutritionFacts";
import Card from "./Card";
import styled from "styled-components";

export default function NutritionAndCardList(props) {
  const { card_list = [], card_list_title } = props;
  const NutritionContainer = styled.div`
    border-radius: 40px;
  `;
  return (
    <Container className="mt-5">
      <Row>
        <NutritionContainer
          className="mt-2 shadow p-4 bg-light col-12 col-md-6"
          xs={12}
          md={6}
        >
          <NutritionalFacts {...props} />
        </NutritionContainer>
        <Col className="d-flex flex-column mt-5 mt-md-0" xs={12} md={6}>
          <span className="text-center">
            <h1 className="roboto-slab">{card_list_title}</h1>
          </span>
          {card_list.map((element, index) => {
            return <Card key={index} {...element} />;
          })}
        </Col>
      </Row>
    </Container>
  );
}

NutritionAndCardList.propTypes = {
  card_list: PropTypes.array,
  card_list_title: PropTypes.string
};
