import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function ProductName(props) {
  const { font, fontModification, fontSize = "25", name, textAlignment } = props;
  const useStyles = makeStyles(() => {
    return {
      container: {
        marginBottom: '2rem',
        marginTop: '2rem',
        padding:'0 1rem'
      },
      label: {
        fontFamily: font.label ? font.label : font,
        fontSize: fontSize ? `${fontSize}px` : "25px",
        fontStyle: fontModification === "italic" ? "italic" : "inherit",
        fontWeight: fontModification === "bold" ? "bold" : "inherit",
        textAlign: textAlignment,
      },
    };
  });

  const classes = useStyles();
  return (
    <Grid className={classes.container} container>
      <Grid className={classes.label} item xs={12}>
        {name}
      </Grid>
    </Grid>
  );
}
