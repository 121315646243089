import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./components/Header";
import ProductDetails2 from "./components/ProductDetails2";
import ImageCarousel from "./components/ImageCarousel";
import NutritionalFacts from "./components/NutritionFacts";
import Footer from "./components/Footer";

function UDI(props) {
  const { propertiesMapList = {}, name, productId } = props.product.asset.product;
  const {
    images,
    batchNumber,
    dateOfBaking,
    description,
    itemNumber,
    nutritionInfo,
    allergy = [],
    ingredients,
    list = [],
    related_products = [],
    gluten_free
  } = propertiesMapList;
  const social_media = [
    {
      link: "https://www.linkedin.com/company/locatorx/",
      icon: "fab fa-linkedin-in"
    },
    { link: "https://twitter.com/LocatorX", icon: "fab fa-twitter-square" },
    { link: "https://www.instagram.com/locatorx", icon: "fab fa-instagram" },
    {
      link: "https://www.facebook.com/LocatorX",
      icon: "fab fa-facebook-square"
    }
  ];
  const productDetailsList = [
    { label: "Product Id", value: productId },
    { label: "Batch", value: batchNumber },
    { label: "Date of Baking", value: dateOfBaking },
    { label: "Item Number", value: itemNumber },
    { label: "Ingredients", value: ingredients }
  ];

  const relatedProducts = related_products.map((element, index) => {
    const { title, image, description, link } = element;
    return (
      <div
        key={index}
        className="card align-self-center mt-3 shadow-sm"
        style={{ width: "18rem" }}
      >
        <a href={link} rel="noopener noreferrer" target="_blank">
          <img className="card-img-top" src={image} alt="Card" />
          <div className="card-body">
            <h6 className="text-center font-weight-bold">{title}</h6>
            <p className="card-text">{description}</p>
          </div>
        </a>
      </div>
    );
  });

  const allergies = allergy.map((element, index) => {
    const { label, value } = element;
    return (
      <div key={index}>
        {label} {value}
      </div>
    );
  });

  const descriptionList = list.map((element, index) => {
    return (
      <li key={index} className="m-3">
        {element}
      </li>
    );
  });

  const logo =
    props.organization.length > 0
      ? props.organization.properties.logo
      : "https://i.imgur.com/2yr8we4.png";

  return (
    <div className="udi-page">
      <Header logo={logo} />
      <Container className="mt-md-5">
        <Row>
          <Col xs={12} md={6}>
            <div className="mt-5 product-images position-relative">
              <ImageCarousel images={images} />
              {gluten_free ? (
                <span className="roboto-slab h3 gluten-free text-white w-100 text-center">
                  Certified Gluten Free
                </span>
              ) : null}
            </div>
          </Col>
          <Col className="roboto-slab mb-3" xs={12} md={6}>
            <ProductDetails2 label={name} list={productDetailsList} />
            <div className="text-uppercase font-weight-bold">
              Contains {allergies}{" "}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="zig-zag-top zig-zag-bottom bg-udi-primary">
        <Container>
          <Row>
            <Col className="roboto-slab" xs={12}>
              <h1 className="text-center w-100 mb-3">Description</h1>
              <span className="lead">{description}</span>
              <ul className="mt-3">{descriptionList}</ul>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="my-5 pb-3">
        <Row>
          <Col className="nutrition mt-2 bg-light shadow p-4" xs={12} md={6}>
            {/* <NutritionalFacts list={nutritionInfo} /> */}
          </Col>
          <Col className="d-flex flex-column mt-5 mt-md-0" xs={12} md={6}>
            <span className="text-center">
              <h1 className="roboto-slab">Related Products</h1>
            </span>
            {relatedProducts}
          </Col>
        </Row>
      </Container>
      <Footer socialMedia={social_media} logo={logo} />
    </div>
  );
}

export default UDI;
