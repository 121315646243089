import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import styled from "styled-components"

export default function Image(props) {
  const { image, subText, link, altText, alignment = "text-center", imgWidth="100%" } = props;

  const Image = styled.img`
    max-width: ${imgWidth};
    height: auto;
  `
  return (
    <Container>
      <Row className={`my-5 ${alignment}`}>
        <Col xs={12}>
          {link ? (
            <a target="__blank" href={link}>
              <Image alt={altText} alt="car" className="img-fluid" src={image} />
            </a>
          ) : (
            <Image alt={altText} className="img-fluid" src={image} />
          )}
          {subText ? (
            <footer className="blockquote-footer mt-2">{subText} </footer>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

Image.propTypes = {
  image: PropTypes.string,
  subText: PropTypes.string,
  link: PropTypes.string,
  altText: PropTypes.string,
  alignment: PropTypes.string
};
