import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// IDLE comes from the asset
// Batch Level Data comes from the batch

export default function SecondaryDataElement(props) {
  const {
    asset = {},
    batchLevelData = "",
    dataElementType = "",
    font = { label: "DejaVu Sans", value: "DejaVu Sans" },
    fontModification,
    fontSize = 20,
    item = "",
    itemLevelData = "",
    textAlignment = "center",
    productLevelDataTitle = "",
    productLevelData = "",
  } = props;
  const {
    batch = {},
    timeCreatedString,
    propertiesMap = {},
    assetType,
  } = asset;
  const { description, destinationString, name, originString, tag } = batch;

  const renderedItem = () => {
    if (dataElementType === "Batch Level Data") {
      switch (batchLevelData) {
        case "Batch Description":
          return `Description: ${description}`;
        case "Batch ID":
          return `Batch ID: ${tag}`;
        case "Batch Name":
          return `Batch Name: ${name}`;
        case "Batch Origin":
          return `Batch Origin: ${originString}`;
        case "Date Created":
          return `Date Created: ${timeCreatedString}`;
        case "Final Destination":
          return `Final Destination: ${destinationString}`;
        case "Unit of Measure":
          return `Unit of Measure: ${assetType}`;
        default:
          return;
      }
    } else if (dataElementType === "Item Level Data") {
      return propertiesMap[itemLevelData]
        ? `${itemLevelData}: ${propertiesMap[itemLevelData]}`
        : null;
    } else if (dataElementType === "Product Level Data") {
      return `${productLevelDataTitle}: ${productLevelData}`;
    } else {
      return item;
    }
  };

  const useStyles = makeStyles(() => {
    return {
      container: {
        marginBottom: "2rem",
        marginTop: "2rem",
      },
      label: {
        fontFamily: font.label ? font.label : font,
        fontSize: fontSize ? `${fontSize}px` : "20px",
        fontStyle: fontModification === "italic" ? "italic" : "inherit",
        fontWeight: fontModification === "bold" ? "bold" : "inherit",
        textAlign: textAlignment,
      },
    };
  });

  const classes = useStyles();
  return (
    <Grid className={classes.container} container>
      <Grid className={classes.label} item xs={12}>
        {renderedItem()}
      </Grid>
    </Grid>
  );
}
