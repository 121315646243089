import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import PropTypes from 'prop-types'

export default function Header(props) {
  const { logo } = props;

  return (
    <Navbar bg="dark" expand="lg">
      <Navbar.Brand href="#home">
        <img
          alt="company logo"
          className="d-inline-block align-top"
          src={logo}
          style={{ width: "10rem" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link className="text-white" href="#home">
            Products
          </Nav.Link>
          <Nav.Link className="text-white" href="#link">
            Technology
          </Nav.Link>
          <Nav.Link className="text-white" href="#link">
            About
          </Nav.Link>
          <Nav.Link className="text-white" href="#link">
            Blog
          </Nav.Link>
          <Nav.Link className="text-white" href="#link">
            Contact
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

Header.propTypes = {
  logo: PropTypes.string
}