import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./components/Header";
import ProductDetails2 from "./components/ProductDetails2";
import ImageCarousel from "./components/ImageCarousel";
import RelatedProducts from "./components/RelatedProducts";
import ProductDescription from "./components/ProductDescription";
import YouTube from "react-youtube";
import Specs from "./components/Specs";
import Footer from "./components/Footer";

import "react-accessible-accordion/dist/fancy-example.css";

function GenericProductRetailer(props) {
  const social_media = [
    {
      link: "https://www.linkedin.com/company/locatorx/",
      icon: "fab fa-linkedin-in"
    },
    { link: "https://twitter.com/LocatorX", icon: "fab fa-twitter-square" },
    {
      link: "https://www.instagram.com/locatorx",
      icon: "fab fa-instagram"
    },
    {
      link: "https://www.facebook.com/LocatorX",
      icon: "fab fa-facebook-square"
    }
  ];

  const { product } = props.product.asset;
  const { description, gtin, name, productId, propertiesMap } = product;
  const {
    images = "",
    related_products = "",
    youtube_videos = "",
    extended_description = "",
    specs = ""
  } = propertiesMap;

  const productDetailsList = [
    { label: "ProductId", value: productId },
    { label: "Gtin", value: gtin },
    { label: "Description", value: description }
  ];
  const youtubeVideos =
    youtube_videos.length > 0
      ? youtube_videos.map((element, index) => {
          return (
            <YouTube
              containerClassName={"h_iframe"}
              key={index}
              videoId={element}
            />
          );
        })
      : null;
  const logo =
    props.organization.length > 0
      ? props.organization.properties.logo
      : "https://i.imgur.com/2yr8we4.png";

  return (
    <div className="product-page">
      <Header logo={logo} />
      <Container className="mt-5">
        <Row>
          <Col xs={12} md={6}>
            <div className="mt-5 h-100 product-images">
              <ImageCarousel images={images} />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <ProductDetails2 label={name} list={productDetailsList} />
          </Col>
        </Row>
      </Container>
      <div
        className="bg-secondary shadow mt-5 cover"
        style={{ backgroundImage: `url(https://connectx-staging.locatorx.com/images/bike-4-overlay.jpg)` }}
      >
        <Container className="text-light py-5">
          <Row>
            <Col xs={12} md={12}>
              <span className="text-center">
                <h2 className="mb-4">Go Places Others Wont</h2>
              </span>
              <ProductDescription description={extended_description} />
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="my-5">
        <Row>
          <Col className="youtube mt-5" xs={12} md={6}>
            {youtubeVideos}
          </Col>
          <Col className="specs" sm={12} md={6}>
            <div className="text-center specs my-4 h2 w-100">
              <span className="fancy">Specs</span>
            </div>
            <Specs props={specs} />
          </Col>
          <Col className="my-5 related-products" sm={12} md={12}>
            <div className="text-center related-products my-4 h2 w-100 overflow-hidden">
              <span className="fancy">Related Products</span>
            </div>
            <RelatedProducts list={related_products} />
          </Col>
        </Row>
      </Container>
      <Footer socialMedia={social_media} logo={logo} />
    </div>
  );
}

export default GenericProductRetailer;
