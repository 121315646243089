import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { PresentationalPicker } from "../../Utils/PCABuilder/PresentationalPicker";

export default function TemplateRenderer(props) {
  const { product = {} } = props;
  const { propertiesMap = {} } = product;
  const { template = {} } = propertiesMap;
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let validParam = params.get("valid");

  const [activeTemplate, setActiveTemplate] = useState({});
  const [page, setPage] = useState("");

  useEffect(() => {
    console.log("useEffect", validParam);
    // This searched to see if the scan is valid. Depending on this we will either
    // display the authentication page or error

    // Here we set whether the user see's the warning or authenticated page
    setPage(validParam === 'true' ? "Authentication Page" : "Warning Page");

    Object.keys(template).forEach((item) => {
      // Here we find the default template to display
      if (template[item].defaultTemplate) {
        setActiveTemplate(template[item]);
      }
    });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        {activeTemplate[page]
          ? activeTemplate[page].map((item, index) =>

          // Im sorry for passing so many freaking things into this function. 
          // Its for a good cause, I swear.
              PresentationalPicker(item, props, null, index, setPage)
            )
          : null}
      </Grid>
    </Grid>
  );
}
