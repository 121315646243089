import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components"

export default function SocialMedia(props) {
  const socialMedia = props.map((element, index) => {
    const { icon, link, type } = element;
    let linkType = "";

    const StyledLink = styled.a`
      color: #31365D;
    `

    switch (type) {
      case "email":
        linkType = (
          <StyledLink
            href={`mailto:${link}`}
            rel="noopener noreferrer"
          >
            <i className={`${icon} fa-2x`} />
          </StyledLink>
        );
        break;
      case "link":
        linkType = (
          <StyledLink
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className={`${icon} fa-2x`} />
          </StyledLink>
        );
        break;
      case "phone":
        linkType = (
          <StyledLink
            href={`tel:${link}`}
            rel="noopener noreferrer"
          >
            <i className={`${icon} fa-2x`} />
          </StyledLink>
        );
    }

    return (
      <div key={index} className="mx-3">
        {linkType}
      </div>
    );
  });

  return socialMedia;
}

SocialMedia.propTypes = {
  icon: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string

};
