import React from "react";
import { Col } from "react-bootstrap";
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";

function ModalWithMap(props) {
  const {
    hideModal,
    title,
    list,
    mapToken,
    viewport,
    modalMapWidth,
    longitude,
    latitude,
    onViewportChange
  } = props;
  return (
    <div className="lightbox">
      <div className="lightbox-inner">
        <div
          className="position-absolute h-100 w-100"
          onClick={() => {
            hideModal();
          }}
        />
        <div className="row content bg-light pt-md-4 pt-2 px-4 custom-modal shadow-lg">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              className="float-right bg-transparent border-0"
              aria-label="Close Modal Box"
              onClick={() => {
                hideModal();
              }}
            >
              &times;
            </button>
          </div>
          <Col xs={12} md={12}>
            {list.map((element, index) => {
              const { label, content } = element;
              return (
                <div key={index} className="my-3">
                  <div className="font-weight-bold border-bottom mb-2">
                    {label}
                  </div>
                  {content}
                </div>
              );
            })}
          </Col>
          <Col
            xs={12}
            md={12}
            className="d-md-flex d-none justify-content-center"
          >
            <ReactMapGL
              mapboxApiAccessToken={mapToken}
              {...viewport}
              height={300}
              width={modalMapWidth}
              onViewportChange={viewport => {
                onViewportChange(viewport);
              }}
            >
              {longitude && latitude ? (
                <Marker longitude={longitude} latitude={latitude}>
                  <div>
                    <i className="fas fa-map-marker-alt" />
                  </div>
                </Marker>
              ) : null}
              <div style={{ position: "absolute", right: 0 }}>
                <NavigationControl
                  showCompass={false}
                  onViewportChange={viewport => onViewportChange(viewport)}
                />
              </div>
            </ReactMapGL>
          </Col>
        </div>
        <div
          className="w-100 position-absolute"
          onClick={() => {
            hideModal();
          }}
        />
      </div>
    </div>
  );
}

export default ModalWithMap;
