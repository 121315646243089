export const retrieveAsset = async (assetId, props) => {
  const { apiUrl, token } = props
  
  const results = await fetch(`${apiUrl}assets/${assetId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err);
    });
  return results;
};