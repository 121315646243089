import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import BorderedSpec from "./Ink/BorderedSpec";
import styled from "styled-components";

export default function BorderedSpecs(props) {
  const { list = [], backgroundColor = "white", textColor = "inherit" } = props;
  const MainContainer = styled.div`
    background-color: ${backgroundColor};
    color: ${textColor};
  `;
  return (
    <MainContainer className="container">
      <Row>
        <Col xs={12}>
          {list.map((element, index) => {
            const { title, value } = element;

            return <BorderedSpec key={index} title={title} value={value} />;
          })}
        </Col>
      </Row>
    </MainContainer>
  );
}

BorderedSpecs.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, value: PropTypes.string })
  ),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string
};
