import React from "react";
import { Container, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import ReactTable from "react-table";

import "react-table/react-table.css";
import "mapbox-gl/dist/mapbox-gl.css";

import Header from "./components/Header";
import ImageCarousel from "./components/ImageCarousel";
import Specs from "./components/Specs";
import Footer from "./components/Footer";
import ModalWithMap from "./components/ModalWithMap";

export default class GenericLogistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSelection: "",
      activeValues: [],
      activeButton: "recent-scans",
      modalMapWidth: 750,
      mapToken:
        "pk.eyJ1IjoiZGFuaWVsZG91YW5na2Vzb25lIiwiYSI6ImNqeGYxMTF5YTA5NjQzeW1mMmM4eXIxdWIifQ.jqF1at0nWobKgC3t1OY4kw",
      viewport: {
        zoom: 8,
      },
      modalShow: props.modalShow,
    };
    this.escFunction = this.escFunction.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.viewportChange = this.viewportChange.bind(this);
  }

  componentDidMount() {
    const { scans = {} } = this.props;
    const { recentScans } = scans;
    if (recentScans) {
      const tempScans = {};
      recentScans.forEach((element) => {
        const { assetHistoryId } = element;
        tempScans[assetHistoryId] = element;
      });
      this.setState({ activeValues: tempScans });
    }
    this.updateDimensions.bind(this);
    window.addEventListener("resize", this.updateDimensions.bind(this));
    document.addEventListener("keydown", this.escFunction, false);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.hideModal();
    }
  }

  updateDimensions() {
    //this function is used to track the window width so we can trigger the resize on the react-map-gl library
    if (window.innerWidth < 990 && window.innerWidth > 769) {
      this.setState({ modalMapWidth: 650 });
    } else if (window.innerWidth < 768) {
      this.setState({ modalMapWidth: (window.innerWidth / 100) * 76 });
    } else {
      this.setState({ modalMapWidth: 750 });
    }
  }

  hideModal() {
    this.setState({ modalShow: false, viewport: {} });
  }

  viewportChange(viewport) {
    this.setState({ viewport });
  }

  render() {
    const { activeValues, activeSelection, activeButton } = this.state;
    const { scans = {} } = this.props;
    const { recentScans = [] } = scans;
    const {
      product,
      timeCreated,
      timeModified,
      batch,
      assetType,
      serialNumber,
      productTag,
    } = this.props.product.asset;
    const { batchId } = batch;
    const { productId, properties, gtin, name, propertiesMap } = product;

    const { images } = propertiesMap;
    const specsContent = {
      "Asset Type": assetType,
      "Serial Number": serialNumber,
      "Product ID": productId,
      "Product Tag": productTag,
      "Batch ID": batchId,
      Properties: properties,
      Gtin: gtin,
      "Time Created": timeCreated,
      "Time Modified": timeModified,
    };

    const social_media = [
      {
        link: "https://www.linkedin.com/company/locatorx/",
        icon: "fab fa-linkedin-in",
      },
      { link: "https://twitter.com/LocatorX", icon: "fab fa-twitter-square" },
      { link: "https://www.instagram.com/locatorx", icon: "fab fa-instagram" },
      {
        link: "https://www.facebook.com/LocatorX",
        icon: "fab fa-facebook-square",
      },
    ];

    const tableColumns = [
      {
        Header: "Asset Tag",
        accessor: "assetTag",
        style: { textAlign: "left" },
      },
      {
        Header: "Asset Type",
        accessor: "assetType",
        style: { textAlign: "center" },
      },
      {
        Header: "History Type",
        accessor: "action",
        style: { textAlign: "center" },
      },
      {
        Header: "Location",
        accessor: "string",
        style: { textAlign: "center" },
      },
      {
        Header: "Time",
        accessor: "timeOfLog",
        style: { textAlign: "center" },
      },
      {
        Header: "Flagged",
        accessor: "flagged",
        Cell: (row) => {
          const icon = row.original.flagged ? (
            <div className="w-100 text-danger text-center">
              <i className="fas fa-flag" />
            </div>
          ) : null;
          return icon;
        },
      },
      {
        Header: "Action",
        accessor: "productId",
        filterable: false,
        className: "text-center",
        Cell: (row) => {
          return (
            <button
              className="btn btn-secondary"
              onClick={() => {
                const { assetHistoryId, latitude, longitude } = row.original;
                return this.setState({
                  activeSelection: assetHistoryId,
                  viewport: { latitude, longitude, zoom: 8 },
                  modalShow: true,
                });
              }}
            >
              View
            </button>
          );
        },
      },
    ];

    const logo = "https://i.imgur.com/2yr8we4.png";

    const activeContent = activeValues[activeSelection]
      ? activeValues[activeSelection]
      : [];

    const {
      assetHistoryId,
      assetId,
      assetTag,
      timeOfLogString,
      latitude,
      longitude,
    } = activeContent;

    const modalBodyContent = [
      { label: "Asset History Id", content: assetHistoryId },
      { label: "Asset Id", content: assetId },
      { label: "Date and Time", content: timeOfLogString },
      { label: "Asset Tag", content: assetTag },
      { label: "Asset Type", content: assetType },
    ];

    const tableHeaders = [
      { title: "Recent Scans", value: "recent-scans" },
      { title: "Product Info", value: "product-info" },
      { title: "Foo Info", value: "foo-info" },
    ].map((element, index) => {
      return (
        <Col
          key={index}
          className={`choice ${
            activeButton === "recent-scans" ? "border-bottom" : ""
          }`}
          md={4}
        >
          <Button
            className={` border-bottom border-0 bg-transparent w-100 ${
              activeButton === element.value ? "active text-dark" : "text-muted"
            }`}
            onClick={() => {
              this.setState({ activeButton: element.value });
            }}
          >
            {" "}
            {element.title}{" "}
          </Button>
        </Col>
      );
    });

    return (
      <div className="fairdale-logistics position-relative">
        <Header logo={logo} />
        <Container className="mt-5">
          <Row className="mb-2">
            <Col xs={12} md={6}>
              <h1 className="display-4 mb-5">{name}</h1>
              <Specs props={specsContent} />
            </Col>
            <Col xs={12} md={6}>
              <div className="mt-5 h-100 product-images">
                <ImageCarousel images={images} />
              </div>
            </Col>
            <ButtonGroup className="col-md-12 mt-5 mb-3 text-center">
              {tableHeaders}
            </ButtonGroup>
          </Row>
          <Row className="mb-5">
            <Col xs={12}>
              {activeButton === "recent-scans" ? (
                <ReactTable data={recentScans} columns={tableColumns} />
              ) : null}
              {activeButton === "product-info" ? (
                <Specs props={specsContent} />
              ) : (
                ""
              )}
              {activeButton === "foo-info" ? <Specs props={batch} /> : ""}
            </Col>
          </Row>
        </Container>
        {this.state.modalShow ? (
          <ModalWithMap
            hideModal={this.hideModal}
            longitude={longitude}
            latitude={latitude}
            title={this.state.activeSelection}
            onViewportChange={this.viewportChange}
            list={modalBodyContent}
            {...this.state}
          />
        ) : null}
        <Footer logo={logo} socialMedia={social_media} />
      </div>
    );
  }
}
