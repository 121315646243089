import React from "react";
import styled from "styled-components";

function BorderedLink(props) {
  const { link, icon, content, buttonColor = "#0096d6" } = props;
  const BorderedButton = styled.div`
    border: 3px solid ${buttonColor};
    padding: 20px 20px 26px 20px;
    a {
      text-decoration: none;
      color: #222;
    }
    &:hover {
      background-color: ${buttonColor};
      color: white;

      a {
        color: white;
      }
    }
  `;
  return (
    <BorderedButton className="text-center font-weight-light">
      <i className={`fa ${icon} mr-2`} aria-hidden="true" />
      <a href={link} target="__blank">
        {content}
      </a>
    </BorderedButton>
  );
}

export default BorderedLink;
