import React from "react";
import styled from 'styled-components';
import Tabs from "./Ink/Tabs";
import ComponentPicker from "../Utils/ComponentPicker";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

export default class TabbedSections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: ""
    };
    this.switchTab = this.switchTab.bind(this);
  }

  componentDidMount() {
    const { sections = [] } = this.props;
    if (sections.length > 0) {
      const { title } = sections[0];
      this.setState({ activeSection: title });
    }
  }

  switchTab(tab) {
    this.setState({ activeSection: tab });
  }

  render() {
    const { sections } = this.props;
    const { activeSection } = this.state;
    const tabs = [];

    sections.forEach(element => {
      tabs.push({
        label: element.title,
        click: () => {
          this.switchTab(element.title);
        }
      });
    });

    return (
      <Container>
        <Row>
          <Col className="border-bottom" xs={12}>
            <Tabs tabs={tabs} activeClass={activeSection} />
          </Col>
          <Col xs={12}>
            {sections.map(element => {
              const { template = [] } = element;
              return template.map((section, index) => {
                return (
                  <div
                    className={element.title === activeSection ? "" : "d-none"}
                    key={index}
                  >
                    {ComponentPicker(section)}
                  </div>
                );
              });
            })}
          </Col>
        </Row>
      </Container>
    );
  }
}

TabbedSections.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      template: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string }))
    })
  )
};
