import React from "react";
import ComponentPicker from "./Utils/ComponentPicker";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import TemplateRenderer from "./Pages/TemplateRenderer/TemplateRenderer"

export default function Foo(props) {
    const { propertiesMap = {} } = props.product;
    const { template = [] } = propertiesMap;
    
    return (
      <div>
      {/* This is a little messy but this repo has been through many iterations. SOOO many iterations. And we have to accomodate for 
      older implementation, until we can get a 'all clear' that marketing/sales is not using any of the old iterations.
      tempalte.map is the old version that needs to be deprecated  */}

      {Array.isArray(template) ? (
        template.map((element, index) => {
          const { size = "", parentClass = "", backgroundColor } = element;
          const Section = styled.div`
            background-color: ${backgroundColor};
          `;
          return (
            <Section key={index} className={`${size} ${parentClass}`}>
              {ComponentPicker(element, { ...props })}
            </Section>
          );
        })
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <TemplateRenderer {...props} />
          </Grid>
        </Grid>
      )}
    </div>
    );
}
