import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ImageCarousel from "./ImageCarousel";
import ProductDetailsWithBulletList from "./ProductDetailsWithBulletList"
import PropTypes from 'prop-types'

export default function BulletListWithImages(props) {
  return (
      <Container className="mt-5">
        <Row>
          <Col xs={12} md={6} className="mt-5 h-100 product-images">
            <ImageCarousel {...props} />
          </Col>
          <Col xs={12} md={6}>
            <ProductDetailsWithBulletList {...props} />
          </Col>
        </Row>
      </Container>
  );
}

BulletListWithImages.propTypes = {
  images: PropTypes.array,
  list: PropTypes.array,
  label: PropTypes.string,
  productId: PropTypes.string
}