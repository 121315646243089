import React from "react";

function BorderedSpec(props) {
  const { title, value } = props
  return (
    <div className="d-flex h5 mb-0">
      <div className="w-25 border-top border-bottom border-right py-3">
        {title}  
      </div>
      <div className="w-75 border-top border-bottom py-3 pl-4 font-weight-light">
        {value}
      </div>
    </div>
  )
}

export default BorderedSpec