import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import ImageCarousel from "./ImageCarousel";
import ProductDetails2 from "./ProductDetails2";

export default function ListWithImages(props) {
  const { backgroundColor = 'white', textColor = 'inherit' } = props
  const MainContainer = styled.div`
    background-color: ${backgroundColor};
    color: ${textColor};
  `
  return (
    <MainContainer className="container">
      <Row>
        <Col xs={12} md={6} className="mt-5 h-100 product-images">
          <ImageCarousel {...props} />
        </Col>
        <Col xs={12} md={6}>
          <ProductDetails2 {...props} />
        </Col>
      </Row>
    </MainContainer>
  );
}

ListWithImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  list: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  label: PropTypes.string
};
