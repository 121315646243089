import React from "react";
import request from "superagent";
import GenericLogistics from "./GenericLogistics";

export default class Logistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productItemId: props.match.params.assetId
    };
  }

  componentDidMount() {
    const productItemId = this.props.match.params.assetId || "";
    request
      .get(`${this.props.apiUrl}assets/${productItemId}`)
      .set("auth-token", this.props.token)
      .set("accept", "application/json")
      .then(res => {
        this.setState({
          productType: res.body.asset.product.propertiesMap.productType,
          product: res.body
        });
      });

    request
      .get(`${this.props.apiUrl}assets/${productItemId}/scans`)
      .set("auth-token", this.props.token)
      .set("accept", "application/json")
      .then(res => {
        this.setState({ scans: res.body });
      });
  }

  render() {
    const { productType, product, scans } = this.state;

    return (
      <div>
        {productType ? (
          <GenericLogistics product={product} scans={scans} {...this.props} />
        ) : null}
      </div>
    );
  }
}
