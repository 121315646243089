import React from "react"
import GenericProductRetailer from "../../GenericProductRetailerPage";
import UDI from "../../Udi";
import Car from "../../Car";
import Ink from "../../Ink";
import Foo from "../../Foo";

export function templatePicker(product, props, state) {
  const { propertiesMap = {} } = product;
  const { productType = {} } = propertiesMap;

  switch (productType) {
    case "bicycle":
      return <GenericProductRetailer product={product} {...props} />;
    case "UDI":
      return <UDI product={product} {...props} />;
    case "car":
      return <Car product={product} {...props} />;
    case "ink-catridge":
      return <Ink product={product} {...props} />;
    default:
      return <Foo {...state} />;
  }
}
