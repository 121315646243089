import React from "react";
import { Container, Row, Jumbotron, Button } from "react-bootstrap";

function Hero(props) {
  const { image, subText, title } = props;
  return (
    <Container>
      <Row>
        <div className="my-5 col-12">
          <Jumbotron
            className="text-white shadow jumbotronBlock"
            style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover' }}
          >
            <h1>{title}</h1>
            <p>{subText}</p>
            <p>
              <Button variant="primary">Learn more</Button>
            </p>
          </Jumbotron>
        </div>
      </Row>
    </Container>
  );
}

export default Hero;
