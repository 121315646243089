import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";

export default function TitleAndList(props) {
  const { list, title } = props;
  return (
    <Container>
      <Row className="my-5">
        <Col
          className="h2 mt-3 font-weight-light text-center d-flex align-items-center justify-content-center"
          xs={12}
          md={6}
        >
          <span>{title}</span>
        </Col>
        <Col xs={12} md={6}>
          <ol className="font-weight-light">
            {list.map((element, index) => {
              return (
                <li className="my-3" key={index}>
                  {element}
                </li>
              );
            })}
          </ol>
        </Col>
      </Row>
    </Container>
  );
}

TitleAndList.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string
};
