import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

export default function ModularText(props) {
  const { fontSize = "h2", fontWeight = "font-weight-light", text } = props;
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <div className={`${fontSize} my-5 ${fontWeight}`}>{text}</div>
        </Col>
      </Row>
    </Container>
  );
}

ModularText.propTypes = {
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  text: PropTypes.string
};
