import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";

export default function TextWithTitle(props) {
  const { content, list = [] } = props;

  const descriptionList = list.map((element, index) => {
    const { title, content } = element;
    return (
      <div key={index} className="my-5">
        <h2 className="font-weight-light">{title}</h2>
        {content}
      </div>
    );
  });
  return (
    <Container>
      <Row>
        <Col xs={12}>
          {content ? (
            <div className="my-4 font-weight-bold">{content}</div>
          ) : null}
          {descriptionList}
        </Col>
      </Row>
    </Container>
  );
}

TextWithTitle.propTypes = {
  content: PropTypes.string,
  list: PropTypes.array
};
