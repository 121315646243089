import React from "react";
import PropTypes from "prop-types";
export default function SpecItems(props) {
  const { list = [] } = props;
  const tableRow = list.map((element, index) => {
    const { title, value } = element;
    return (
      <tr key={index}>
        <th scope="row ">
          <span className="text-uppercase">{title}</span>
        </th>
        <td>{value}</td>
      </tr>
    );
  });
  return (
    <table className="table table-striped pointer">
      <tbody>{tableRow}</tbody>
    </table>
  );
}

SpecItems.propTypes = {
  list: PropTypes.array
};
